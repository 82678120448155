import React from "react";
import { PincitesButtonType } from "./PincitesButtonUtils";
import PincitesText from "./PincitesText";
import PincitesDialog from "./PincitesDialog";
import { ErrorCircle20Regular } from "@fluentui/react-icons";
import { makeStyles } from "@fluentui/react-components";
import { PincitesColor } from "./PincitesTheme";

interface ErrorDialogProps {
  isOpen: boolean;
  errorTitle: string;
  errorBody: string;
  onClose: () => void;
}

const useStyles = makeStyles({
  errorHeaderStyle: {
    color: PincitesColor.RED,
    "& *": {
      color: PincitesColor.RED,
    },
  },
});

export default function PincitesErrorDialog({
  isOpen,
  errorTitle,
  errorBody,
  onClose,
}: ErrorDialogProps) {
  const styles = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <PincitesDialog
      isOpen={isOpen}
      primaryActionButtonProps={{
        title: "OK",
        onClick: () => onClose(),
        buttonType: PincitesButtonType.SECONDARY,
      }}
      dialogHeaderProps={{
        headingText: errorTitle || "Looks like something went wrong",
        icon: <ErrorCircle20Regular className={styles.errorHeaderStyle} />,
        customStyle: styles.errorHeaderStyle,
      }}
    >
      <PincitesText>
        {errorBody ||
          "We couldn't load your content because of a technical problem. Please refresh the page to try again, or reach out to us."}
      </PincitesText>
    </PincitesDialog>
  );
}
