"use client";

import React, { useEffect, useState } from "react";
import { Maybe } from "../../types/Types";
import OrganizationContext from "./OrganizationContext";
import { TOrganization } from "../../types/OrganizationsTypes";

export default function OrganizationProvider({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  const [stateOrganization, setStateOrganization] =
    useState<Maybe<TOrganization>>(null);

  const setOrganization = (organization: Maybe<TOrganization>) => {
    setStateOrganization(organization);
    if (organization) {
      localStorage.setItem("organization", JSON.stringify(organization));
    } else {
      localStorage.removeItem("organization");
    }
  };

  useEffect(() => {
    // Fetch the organization from local storage
    const organizationJson = localStorage.getItem("organization");

    try {
      const organization = organizationJson
        ? JSON.parse(organizationJson)
        : null;
      setStateOrganization(organization);
    } catch (e) {
      if (e instanceof SyntaxError && e.message.includes("JSON Parse error")) {
        // Someone messed with the local storage, so clear it
        localStorage.removeItem("organization");
      } else {
        // Something else went wrong, so throw the error
        throw e;
      }
    }
  }, []);

  return (
    <OrganizationContext.Provider
      value={{ organization: stateOrganization, setOrganization }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}
