import React from "react";

export type TErrorModalSettings = {
  errorTitle: string;
  errorBody: string;
};

export type TShowErrorModal = (errorData: TErrorModalSettings) => void;

interface ErrorHandlingContextProps {
  showErrorModal: TShowErrorModal;
  hideErrorModal: () => void;
}

const defaultErrorModalContextValue: ErrorHandlingContextProps = {
  showErrorModal: () => {},
  hideErrorModal: () => {},
};

const ErrorModalContext = React.createContext(defaultErrorModalContextValue);

export default ErrorModalContext;
