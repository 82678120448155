import React from "react";
import { Maybe } from "../../types/Types";
import { TOrganization } from "../../types/OrganizationsTypes";

interface OrganizationContextProps {
  organization: Maybe<TOrganization>;
  setOrganization: (_: Maybe<TOrganization>) => void;
}

const defaultOrganizationContextValue: OrganizationContextProps = {
  organization: null,
  setOrganization: (_: Maybe<TOrganization>) => {},
};

const OrganizationContext = React.createContext(
  defaultOrganizationContextValue,
);

export default OrganizationContext;
