import React, { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { Margin } from "./Margin";
import PincitesFlexbox, {
  PincitesFlexboxDirection,
  PincitesFlexboxAlignItems,
  PincitesFlexboxGap,
} from "./PincitesFlexbox";
import { PincitesButtonType } from "./PincitesButtonUtils";
import PincitesButton from "./PincitesButton";
import PincitesText from "./PincitesText";

export enum PincitesDialogType {
  PRIMARY = "PRIMARY",
  ERROR = "ERROR",
  WARNING = "WARNING",
}

interface PincitesDialogPrimaryActionButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  buttonType?: PincitesButtonType;
  showSpinner?: boolean;
}

type DialogHeaderProps = {
  headingText: string | ReactNode;
  descriptionContent?: string;
  icon?: React.JSX.Element;
  customStyle?: string;
  isErrorDialog?: boolean;
};

function PincitesDialog({
  isOpen,
  onDismiss,
  children,
  primaryActionButtonProps,
  dialogHeaderProps,
}: {
  isOpen: boolean;
  onDismiss?: () => void;
  children: React.ReactNode;
  dialogHeaderProps: DialogHeaderProps;
  primaryActionButtonProps: PincitesDialogPrimaryActionButtonProps;
}) {
  return (
    <Dialog open={isOpen}>
      <DialogSurface aria-label="open-dialog" id="dialog-surface">
        <DialogBody id="dialog-body">
          <DialogTitle id="dialog-title">
            <PincitesDialogHeader
              headingText={dialogHeaderProps.headingText}
              descriptionContent={dialogHeaderProps.descriptionContent}
              icon={dialogHeaderProps.icon}
              customStyle={dialogHeaderProps.customStyle}
            />
          </DialogTitle>
          <DialogContent id="dialog-content">{children}</DialogContent>
          <DialogActions aria-label="dialog-actions" fluid>
            {onDismiss && (
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={onDismiss}
                  aria-label="Cancel"
                >
                  Cancel
                </Button>
              </DialogTrigger>
            )}
            <PincitesButton
              label={primaryActionButtonProps.title}
              onClick={primaryActionButtonProps.onClick}
              isDisabled={primaryActionButtonProps?.disabled}
              showSpinner={primaryActionButtonProps?.showSpinner}
              buttonType={
                primaryActionButtonProps?.buttonType ||
                PincitesButtonType.PRIMARY
              }
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

function PincitesDialogHeader({
  headingText,
  descriptionContent,
  icon,
  customStyle,
}: DialogHeaderProps) {
  return (
    <PincitesFlexbox
      direction={PincitesFlexboxDirection.VERTICAL}
      margin={descriptionContent ? [Margin.MARGIN_BOTTOM_16] : []}
    >
      <DialogTitle>
        {icon ? (
          <PincitesFlexbox
            direction={PincitesFlexboxDirection.HORIZONTAL}
            alignItems={PincitesFlexboxAlignItems.CENTER}
            gap={PincitesFlexboxGap.GAP_4}
            customClassName={customStyle}
            id="dialog-title-flexbox"
          >
            {icon}
            {headingText}
          </PincitesFlexbox>
        ) : (
          headingText
        )}
      </DialogTitle>
      {descriptionContent && <PincitesText>{descriptionContent}</PincitesText>}
    </PincitesFlexbox>
  );
}

export default PincitesDialog;
